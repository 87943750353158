import Vue from 'vue';
import Vuex from 'vuex'

Vue.use(Vuex)

const store = new Vuex.Store({
    state() {
        return {
            messages: [],
            isTalking: false,
            AI_settingBox: {
                text: ''
            },
            streamText: '',



            webSocket: null,
            webSocketMethod:{
                onReceivedPrivateMessage:null,
                onReceivedGroupMessage:null,
                onConversationsUpdate:null,
                onPrivateMessageUpdate:null,
                onGroupMessageUpdate:null,
            },
            initConversation:null,
            loadConversations:null
        }
    },
    getters: {},
    mutations: {},
    actions: {
        getMessage(context) {
            context.state.isTalking = true;
            let newMessages = context.state.messages.slice(-8);
            if (context.state.AI_settingBox.text !== '') {
                let system_message = {
                    role: 'system',
                    content: context.state.AI_settingBox.text,
                    timestamp: Date.now()
                }
                newMessages.unshift(system_message);
            }

            console.log(newMessages)

            Vue.prototype.$http.post('/ai/chat', newMessages).then((res) => {
                try {
                    let message = {
                        role: res.data[0].message.role,
                        content: res.data[0].message.content,
                        timestamp: Date.now()
                    }
                    context.state.messages.push(message);
                    localStorage.setItem('messages_' + Vue.prototype.globalData.currentUser.id, JSON.stringify(context.state.messages));
                } catch (e) {
                    console.log(e)
                    console.log('服务器错误请重试');
                } finally {
                    context.state.isTalking = false;
                }
            })
        },

        getMessageByStream(context,ref) {
            context.state.isTalking = true;
            let newMessages = context.state.messages.slice(-8);
            if (context.state.AI_settingBox.text !== '') {
                let system_message = {
                    role: 'system',
                    content: context.state.AI_settingBox.text,
                    timestamp: Date.now()
                }
                newMessages.unshift(system_message);
            }

            fetch(Vue.prototype.baseURL+'/ai/stream', {
                method: 'POST',
                body: JSON.stringify(newMessages),
                headers: {
                    'Content-Type': 'application/json'
                }
            }).then(response => {
                try {
                    let message = {
                        // role: response.data[0].message.role,
                        role: 'assistant',
                        content: '',
                        timestamp: Date.now()
                    }

                    context.state.messages.push(message);
                    let msgIndex=context.state.messages.length-1;

                    const reader = response.body.getReader();
                    console.log('stream...')
                    // 逐步接收流数据
                    const read = () => {
                        return reader.read().then(({done, value}) => {
                            if (done) {
                                context.state.isTalking = false;
                                return;
                            }
                            // 将收到的数据转为文本
                            const text = new TextDecoder('utf-8').decode(value);
                            // console.log(text)
                            context.state.messages[msgIndex].content+=text;
                            localStorage.setItem('messages_' + Vue.prototype.globalData.currentUser.id, JSON.stringify(context.state.messages));
                            Vue.prototype.$nextTick(() => {
                                ref.scrollView.scrollTop = ref.messageList.scrollHeight;
                            });
                            // 继续读取下一块数据
                            return read();
                        });
                    };

                    // 开始读取数据
                    return read();

                } catch (e) {
                    console.log(e)
                    console.log('服务器错误请重试');
                    this.$message.error('服务器错误请重试');
                } finally {

                }

            }).catch(error => {
                console.error('Error:', error);
                this.$message.error('服务器错误请重试');
            });
        }
    }
})

export default store
