import Vue from 'vue';
import App from './App.vue';
import router from './router/index';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import axios from 'axios';
import store from './store'

Vue.config.productionTip = false;

Vue.prototype.globalData = {
    currentUser: null
};

// Vue.prototype.wsURL='ws://localhost:8088/websocket/'
// Vue.prototype.baseURL='http://localhost:8088'

Vue.prototype.wsURL='ws://www.hmytest.cn/websocket/'
Vue.prototype.baseURL='http://www.hmytest.cn'
Vue.use(ElementUI);
axios.defaults.baseURL=Vue.prototype.baseURL;

Vue.prototype.$http=axios;

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app');


