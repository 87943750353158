import Vue from 'vue';
import VueRouter from 'vue-router';

const Home = () => import('@/views/Home');
const Login = () => import('@/views/Login');
const Register = () => import('@/views/Register.vue')
const Conversations = () => import('@/views/Conversations');
const Contacts = () => import('@/views/Contacts');
const PrivateChat = () => import('@/views/PrivateChat');
const GroupChat = () => import('@/views/GroupChat');
const ChatGPT = () => import('@/views/ChatGPT.vue')
const  Moments = () => import('@/views/Moments .vue')
const Test = () => import('@/views/Test.vue')
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        component: Home,
        redirect: '/conversations',
        children: [
            {
                path: 'conversations',
                component: Conversations,
                children: [
                    {
                        path: 'privatechat/:id',
                        component: PrivateChat,
                    },
                    {
                        path: 'groupchat/:id',
                        component: GroupChat,
                    },
                ],
            },
            {
                path: 'contacts',
                component: Contacts,
            },
            {
                path: 'chatgpt',
                component: ChatGPT,
            },
            {
                path: 'moments',
                component: Moments,
            },
            {
                path: 'test',
                component: Test,
            },
        ],
    },
    {
        path: '/login',
        component: Login,
    },
    {
        path: '/register',
        component: Register,
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach((to, from, next) => {
    let user = JSON.parse(window.localStorage.getItem('user_save'));
    if (user) {
        Vue.prototype.globalData.currentUser = user;
    }
    if (to.path !== '/login' && to.path !== '/register' && !Vue.prototype.globalData.currentUser) {
        next({path: '/login'});
    } else next()
})

export default router;
